// Webpacker EMP Component entry point
// console.log('- webpacker emp-component loaded');
import 'javascript/bootstrap.js';
import 'javascript/nist-header-footer.js';
import * as d3 from 'javascript/d3.v3.min.js';
//import 'javascript/d3-tip.v3.min.js';

// We can split this out later into another pack (f.e. admin pack, and user pack)

import * as TimelineChart from 'javascript/phases-timeline-chart.js';
Object.assign(window, TimelineChart);

// The assign can be likely embedded into the html to be localized to the page.
import * as EmpPhases from 'javascript/admin/phases.js';
Object.assign(window, EmpPhases);

import * as EmpEvaluations from 'javascript/admin/evaluations.js';
Object.assign(window, EmpEvaluations);

import * as EmpSystemParticipations from 'javascript/admin/system_participations.js';
Object.assign(window, EmpSystemParticipations);

import * as EmpDatasetCollections from 'javascript/admin/dataset_collections.js';
Object.assign(window, EmpDatasetCollections);

import * as EmpLicenseTemplates from 'javascript/admin/license_templates.js';
Object.assign(window, EmpLicenseTemplates);

import * as EmpLeaderboardManagement from 'javascript/admin/leaderboards.js';
Object.assign(window, EmpLeaderboardManagement);

import * as EmpPages from 'javascript/admin/pages.js';
Object.assign(window, EmpPages);

import * as EmpPrivateUserAssets from 'javascript/admin/private_user_assets.js';
Object.assign(window, EmpPrivateUserAssets);

import * as EmpSidebar from 'javascript/sidebar.js';
Object.assign(window, EmpSidebar);

import * as EmpParametersForm from 'javascript/parameters.js';
Object.assign(window, EmpParametersForm);

// Needed in all pages
import * as AppHelpers from 'javascript/application_helpers.js';

// There are some issues around jquery and webpacker loading
// Force-attach to global context
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

export function dtRowCallback(row, data) {};
export function dtHeaderCallback(thead, data) {};