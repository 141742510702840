/* eslint no-console:0 */

// IAD-EMP Core/Root JS Integration Entry Point
//
// This uses webpacker from within rails and is loaded once from lauouts.
// Source all other JS code from here.
//
// Migration from SProckets has been performed according to this article:
// https://medium.com/@coorasse/goodbye-sprockets-welcome-webpacker-3-0-ff877fb8fa79

// NOTES:
// - jquery is included through app/config/webpack/environment.js

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
//const images = require.context('images', true)

// Somehow compiled media image path is having the full component path.
// We softlink the image folder to packs/../images
require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Cover Basic stuff
// rails/ujs is the much needed jquery/ujs wrapper for rails.
require("@rails/ujs").start()

// For action-cable (in the future)
// require("channels")

// Emp Component entry point.
//
// NOTE: Embedding a webpacker within the engine is also possible. An
// article about separate webpack integration in engines can be found
// here:
// https://github.com/rails/webpacker/blob/master/docs/engines.md
// Current State:
// We source the component file from server/config/webpack.yml which represents
// the application pack.
import 'javascript/application.js'

require('datatables.net-bs4');
require('datatables.net-select-bs4');
require('datatables.net-searchpanes-bs4');
